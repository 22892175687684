import { slugify, validateEmail, validatePhoneNumber } from './utils.js';
import {
  handleBackInStockEmail,
  handleKlaviyoNewsletterEmail,
  handleBackInStockSMS,
  handleKlaviyoNewsletterSMS
} from './klaviyo-utils.js';

// Heavily inspired by https://host.sonspring.com/cta-modal/

const ACTIVE = 'active';
const ANIMATION_DURATION = 120;
const BLOCK = 'block';
const CLICK = 'click';
const COLOR = 'color';
const COLLECTIONS = 'collections';
const DATA_HIDE = 'data-product-modal-hide';
const DATA_SHOW = 'data-product-modal-show';
const EMPTY_STRING = '';
const ENTER = 'enter';
const FALSE = 'false';
const FOCUSIN = 'focusin';
const FUNCTION = 'function';
const HIDDEN = 'hidden';
const KEYDOWN = 'keydown';
const NONE = 'none';
const PREFERS_REDUCED_MOTION = '(prefers-reduced-motion: reduce)';
const PRODUCT = 'product';
const SPACE = ' ';
const SPACE_REGEX = /\s+/g;
const TEMPLATE = 'template';
const TRUE = 'true';

const FOCUSABLE_SELECTORS = [
  '[contenteditable]',
  '[tabindex="0"]:not([disabled])',
  'a[href]',
  'audio[controls]',
  'button:not([disabled])',
  'iframe',
  "input:not([disabled]):not([type='hidden'])",
  'select:not([disabled])',
  'summary',
  'textarea:not([disabled])',
  'video[controls]'
].join(',');
const STYLE = `
    <style>
      *,
      *:after,
      *:before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }

      @media ${PREFERS_REDUCED_MOTION} {
        *,
        *:after,
        *:before {
          animation: none !important;
          transition: none !important;
        }
      }

      @keyframes SHOW-OVERLAY {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes SHOW-DIALOG {
        0% {
          transform: translateY(100%);
        }
        100% {
          transform: translateY(0);
        }
      }

      @keyframes HIDE-OVERLAY {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      @keyframes HIDE-DIALOG {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(100%);
        }
      }

      .product-modal__focus-trap {
        opacity: 0;
        overflow: hidden;
        width: 0;
        height: 0;
        position: fixed;
        top: 0;
        left: 0;
      }

      .product-modal__scroll {
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        height: 100%;
        z-index: 100000;
        position: fixed;
        top: 0;
        left: 0;
      }

      .product-modal__overlay {
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-top: 20px;
        width: 100%;
        min-height: 100%;
      }

      .product-modal__dialog {
        background-color: #fff;
        border-radius: 8px 8px 0px 0px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
        padding: 15px;
        width: 500px;
        max-width: 100%;
        position: relative;
        bottom: 0;
      }

      [${DATA_SHOW}='true'] .product-modal__overlay {
        animation-duration: ${ANIMATION_DURATION}ms;
        animation-name: SHOW-OVERLAY;
      }

      [${DATA_SHOW}='true'] .product-modal__dialog {
        animation-duration: ${ANIMATION_DURATION}ms;
        animation-name: SHOW-DIALOG;
      }

      [${DATA_HIDE}='true'] .product-modal__overlay {
        animation-duration: ${ANIMATION_DURATION}ms;
        animation-name: HIDE-OVERLAY;
        opacity: 0;
      }

      [${DATA_HIDE}='true'] .product-modal__dialog {
        animation-duration: ${ANIMATION_DURATION}ms;
        animation-name: HIDE-DIALOG;
        transform: scale(0.95);
      }
    </style>
  `;

const FOCUS_TRAP = `
    <span
      aria-hidden='true'
      class='product-modal__focus-trap'
      tabindex='0'
    ></span>
  `;

const MODAL = `
    <div class='product-modal__scroll' style='display:none'>
      ${FOCUS_TRAP}

      <div class='product-modal__overlay'>
        <div
          aria-modal='true'
          class='product-modal__dialog'
          role='dialog'
          tabindex='-1'
        >
          <slot name='modal'></slot>
        </div>
      </div>

      ${FOCUS_TRAP}
    </div>
  `;

// Get markup.
const markup = [STYLE, MODAL]
  .join(EMPTY_STRING)
  .trim()
  .replace(SPACE_REGEX, SPACE);

// Get template.
const template = document.createElement(TEMPLATE);
template.innerHTML = markup;

class ProductModal extends HTMLElement {
  constructor() {
    // Parent constructor.
    super();

    // Bind context.
    this._bind();

    this._product = null;
    this._productCollections = null;
    this._selectedColor = null;
    this._selectedVariant = null;

    // Shadow DOM.
    this._shadow = this.attachShadow({ mode: 'closed' });

    // Add template.
    this._shadow.append(
      // Clone node.
      template.content.cloneNode(true)
    );

    // Get slots.
    this._slotForModal = this.querySelector("[slot='modal']");

    // Get shadow elements.
    this._focusTrapList = this._shadow.querySelectorAll(
      '.product-modal__focus-trap'
    );
    this._modal = this._shadow.querySelector('.product-modal__dialog');
    this._modalOverlay = this._shadow.querySelector('.product-modal__overlay');
    this._modalScroll = this._shadow.querySelector('.product-modal__scroll');
    this._colors = this.querySelector('[data-product-colors]');
    this._options = this.querySelector('[data-product-options]');
    this._form = this.querySelector('[data-product-form]');
    this._waitlistForm = this.querySelector('[data-product-waitlist-form]');
    this._hiddenProductData = this.querySelector('[data-product-hidden]');
    this._emailInput = this.querySelector('#waitlist-email');
    this._telephoneInput = this.querySelector('#waitlist-telephone');
  }
  set selectedColor(color) {
    this._selectedColor = color;
    this._renderOptions();
  }
  get selectedColor() {
    return this._selectedColor;
  }
  set selectedVariant(id) {
    this._selectedVariant = id;
    this._renderForm();
  }
  get selectedVariant() {
    return this._selectedVariant;
  }
  static get observedAttributes() {
    return [ACTIVE, PRODUCT, COLLECTIONS];
  }
  attributeChangedCallback(name, oldValue, newValue) {
    if (name === ACTIVE) {
      if (oldValue !== newValue) {
        this._setActiveFlag();
      }
    }
    if (name === PRODUCT) {
      this._product = JSON.parse(newValue);
      this._renderModal();
    }
    if (name === COLLECTIONS) {
      this._productCollections = JSON.parse(newValue);
    }
  }
  connectedCallback() {
    this._addEvents();
  }
  disconnectedCallback() {
    this._removeEvents();
  }
  // Helper: bind `this` context
  _bind() {
    // Get property names.
    const propertyNames = Object.getOwnPropertyNames(
      // Get prototype.
      Object.getPrototypeOf(this)
    );

    // Loop through.
    propertyNames.forEach(name => {
      // Bind functions.
      if (typeof this[name] === 'function') {
        this[name] = this[name].bind(this);
      }
    });
  }
  _addEvents() {
    // Prevent doubles.
    this._removeEvents();

    document.addEventListener(FOCUSIN, this._handleFocusIn);
    document.addEventListener(KEYDOWN, this._handleKeyDown);

    this._modalOverlay.addEventListener('click', this._handleClickOverlay);

    if (this._slotForModal) {
      this._slotForModal.addEventListener('click', this._handleClickToggle);
      this._slotForModal.addEventListener(KEYDOWN, this._handleClickToggle);
      this._form.addEventListener('submit', this._addToCart);
      this._waitlistForm.addEventListener('submit', this._addToCart);
      this._telephoneInput.addEventListener('input', hideErrorMsg);
    }
  }
  _removeEvents() {
    document.removeEventListener(FOCUSIN, this._handleFocusIn);
    document.removeEventListener(KEYDOWN, this._handleKeyDown);

    this._modalOverlay.removeEventListener(CLICK, this._handleClickOverlay);

    if (this._slotForModal) {
      this._slotForModal.removeEventListener(CLICK, this._handleClickToggle);
      this._slotForModal.removeEventListener(KEYDOWN, this._handleClickToggle);
      this._form.removeEventListener('submit', this._addToCart);
      this._waitlistForm.removeEventListener('submit', this._addToCart);
      this._telephoneInput.removeEventListener('input', hideErrorMsg);
      this._emailInput.removeEventListener('input', hideErrorMsg);
    }
  }
  _renderModal() {
    if (this._product) {
      this._renderColorSwatches();
      this._renderOptions();
      // cdp product obj and event
      let product = {
        shopifyProductID: String(this._product.id),
        shopifyVariantID: String(this._selectedVariant?.id),
        shopifyVendorName: this._product?.vendor,
        price: this._product.price
      };
      sendCDPProductViewedEvent(product);
      this._focusElement(this._options);
    }
  }

  _renderColorSwatches() {
    const initialColor = this.getAttribute(COLOR);
    this._selectedColor = initialColor || null;
    this._colors.textContent = '';

    const colorOptionIndex = this._product.options.findIndex(
      option => option.toLowerCase() === 'color'
    );
    if (colorOptionIndex < 0) {
      this._selectedColor = null;
      return;
    }
    const colorOption = `option${colorOptionIndex + 1}`;
    const colors = [
      ...new Set(this._product.variants.map(variant => variant[colorOption]))
    ];
    if (colors.length === 1) {
      this.selectedColor = colors[0];
      return;
    }
    const colorSwatches = colors.map(color => {
      if (!this._selectedColor) this.selectedColor = color;
      return `
          <a title="${color}" class="swatch ${slugify(color)} ${
        this._selectedColor === color ? 'active' : ''
      }" data-color="${color}"><span class="swatch-color-square"></span></a>
        `;
    });
    let colorImages = {};
    colors.forEach(color => {
      const colorVariant = this._product.variants.find(variant =>
        variant.title.includes(color)
      );
      if (colorVariant?.featured_image?.src) {
        colorImages[color] = colorVariant?.featured_image?.src;
      }
    });
    this._colors.innerHTML = colorSwatches.join('');
    const p = document.createElement('p');
    p.textContent = 'Select Color';
    this._colors.insertBefore(p, this._colors.firstChild);
    this.querySelectorAll('.swatch').forEach(swatch =>
      swatch.addEventListener('click', e => {
        this.querySelectorAll('.swatch').forEach(swatch =>
          swatch.classList.remove('active')
        );
        e.target.closest('.swatch').classList.add('active');
        this.selectedColor = e.target.closest('.swatch').dataset.color;
      })
    );
  }
  _renderOptions() {
    this._options.textContent = '';
    this._selectedVariant = null;

    let optionButtons = [];
    let variants = this._product.variants;
    const sizeOptionIndex = this._product.options.findIndex(
      option => option.toLowerCase() === 'size'
    );
    const colorOptionIndex = this._product.options.findIndex(
      option => option.toLowerCase() === 'color'
    );
    // If color option filter variants by selected color
    if (this._selectedColor) {
      variants = this._product.variants.filter(
        variant =>
          variant[`option${colorOptionIndex + 1}`] === this._selectedColor
      );
    }
    variants.forEach((variant, idx) => {
      if (!this._selectedVariant) {
        // Select first available option
        if (variant.available) {
          this.selectedVariant = variant;
          // If no available options render form to update add to cart button state
        } else if (variants.length === idx + 1) {
          this._renderForm();
        }
      }
      let title = variant.title;
      // If option is size use option name, else use variant name
      if (sizeOptionIndex >= 0 && colorOptionIndex >= 0)
        title = variant[`option${sizeOptionIndex + 1}`];
      optionButtons.push(`
          <a tabindex="0" title="${title}" class="option ${
        this._selectedVariant?.id === variant.id ? 'active' : ''
      }" data-product-title="${this._product.title}" data-product-price="${(
        this._product.price / 100
      ).toFixed(2)}" data-variant-id="${
        variant.id
      }" data-option data-available="${variant.available}">${title}</a>
        `);
    });
    this._options.innerHTML = optionButtons.join('');
    const p = document.createElement('p');
    p.textContent = sizeOptionIndex >= 0 ? 'Select Size' : 'Select Option';
    this._options.insertBefore(p, this._options.firstChild);

    this.querySelectorAll('[data-option]').forEach(option => {
      option.addEventListener('click', e => {
        this.querySelectorAll('.option').forEach(option =>
          option.classList.remove('active')
        );
        e.target.closest('.option').classList.add('active');
        this.selectedVariant = this._product.variants.find(
          variant => String(variant.id) === option.dataset.variantId
        );
      });
      option.addEventListener('keydown', e => {
        if (e.key === ' ' || e.key === 'Enter') {
          this.querySelectorAll('.option').forEach(option =>
            option.classList.remove('active')
          );
          e.target.closest('.option').classList.add('active');
          this.selectedVariant = this._product.variants.find(
            variant => String(variant.id) === option.dataset.variantId
          );
        }
      });
    });
  }

  _renderForm() {
    const submitButton = document.querySelector('#modal-submit-btn');
    const emailMeBtnModal = document.querySelector('#waitlist-email-me-btn');
    const errorMsgModal = document.querySelector('.waitlist-error');
    const form = this._form;
    const waitlistForm = this._waitlistForm;
    if (this._selectedVariant?.available) {
      submitButton.disabled = false;
      waitlistForm.style.display = 'none';
      form.style.display = 'block';
      textMeBtnModal.removeEventListener('click', toggleWaitlistTextMeBtn);
      emailMeBtnModal.removeEventListener('click', toggleWaitlistEmailMeBtn);
    } else {
      telephoneInputModal.addEventListener('change', () => {
        errorMsgModal.style.display = 'none';
      });
      this._hiddenProductData.setAttribute(
        'data-variant-id',
        this._selectedVariant?.id || this._product.variants[0].id
      );
      this._hiddenProductData.setAttribute('data-product-id', this._product.id);
      form.style.display = 'none';
      waitlistForm.style.display = 'block';
      textMeBtnModal.addEventListener('click', toggleWaitlistTextMeBtn);
      emailMeBtnModal.addEventListener('click', toggleWaitlistEmailMeBtn);
    }
  }

  _addToCart(e) {
    e.preventDefault();
    const variantId = this._hiddenProductData.getAttribute('data-variant-id');
    // const productId = this._hiddenProductData.getAttribute('data-product-id');

    if (emailInputModal && emailInputModal.value !== '') {
      const validatedEmail = validateEmail(emailInputModal.value);
      if (validatedEmail) {
        handleBackInStockEmail(emailInputModal.value, variantId);

        if (acceptsMarketingModal) {
          handleKlaviyoNewsletterEmail(emailInputModal.value);
        }
        // Google
        dataLayer.push({ event: 'Waitlist Joined' });
        handleWaitlistSubmit();
      } else {
        waitlistErrorHandler('email');
      }
    } else if (telephoneInputModal && telephoneInputModal.value !== '') {
      const validatedPhone = validatePhoneNumber(telephoneInputModal.value);
      if (validatedPhone) {
        handleBackInStockSMS(validatedPhone, variantId);
        if (acceptsMarketingModal) {
          handleKlaviyoNewsletterSMS(validatedPhone);
        }
        // Google
        dataLayer.push({ event: 'Waitlist Joined' });
        handleWaitlistSubmit();
      } else {
        waitlistErrorHandler('phone number');
      }
    } else {
      const options = {
        id: this._selectedVariant.id,
        quantity: 1
      };
      BOLD.BMS.QuickCart.addItemToCart(options, button, () =>
        this.setAttribute(ACTIVE, FALSE)
      );
      if (this._product) {
        let klaviyoItem = {
          Name: String(this._product.title),
          ProductID: this._product.id,
          Categories: this._productCollections || this._product.collections,
          ImageURL: `https:${this._product.images[0]}`,
          URL: `https://store.barstoolsports.com/products/${this._product.handle}`,
          Brand: String(this._product.vendor),
          Tags: this._product.tags
        };
        // klaviyo event
        _learnq.push(['track', 'Added to Cart', klaviyoItem]);
      }
    }
  }

  _setActiveFlag() {
    // Get flag.
    const isActive = this.getAttribute(ACTIVE) === TRUE;

    // Set flag.
    this._isActive = isActive;

    // Set display.
    this._toggleModalDisplay(() => {
      // Focus modal?
      if (this._isActive) {
        this._focusModal();
      }
    });
  }
  _focusElement(element) {
    window.requestAnimationFrame(() => {
      if (typeof element.focus === FUNCTION) {
        element.focus();
      }
    });
  }
  _focusModal() {
    window.requestAnimationFrame(() => {
      this._modal.focus();
      this._modalScroll.scrollTo(0, 0);
    });
  }
  _isOutsideModal(element) {
    // Early exit.
    if (!this._isActive || !element) {
      return false;
    }

    // Has element?
    const hasElement = this.contains(element) || this._modal.contains(element);

    // Get boolean.
    const bool = !hasElement;

    // Expose boolean.
    return bool;
  }
  _isMotionOkay() {
    // Get pref.
    const { matches } = window.matchMedia(PREFERS_REDUCED_MOTION);

    // Expose boolean.
    return !matches;
  }
  _toggleModalDisplay(callback) {
    this.setAttribute(ACTIVE, this._isActive);

    if (this._isActive) {
      this._trackModalEvent('opened');
    }

    // Get booleans.
    const isModalVisible = this._modalScroll.style.display === BLOCK;
    const isMotionOkay = this._isMotionOkay();

    // Get delay.
    const delay = isMotionOkay ? ANIMATION_DURATION : 0;

    // Get scrollbar width.
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;

    // Get active element.
    const activeElement = document.activeElement;

    // Cache active element?
    if (this._isActive && activeElement) {
      this._activeElement = activeElement;
    }

    // =============
    // Modal active?
    // =============

    if (this._isActive) {
      // Show modal.
      this._modalScroll.style.display = BLOCK;

      // Hide scrollbar.
      document.documentElement.style.overflow = HIDDEN;

      // Add placeholder?
      if (scrollbarWidth) {
        document.documentElement.style.paddingRight = `${scrollbarWidth}px`;
      }

      // Set flag.
      if (isMotionOkay) {
        this._isHideShow = true;
        this._modalScroll.setAttribute(DATA_SHOW, TRUE);
      }

      // Fire callback.
      callback();

      // Await CSS animation.
      this._timerForShow = window.setTimeout(() => {
        // Clear.
        clearTimeout(this._timerForShow);

        // Remove flag.
        this._isHideShow = false;
        this._modalScroll.removeAttribute(DATA_SHOW);

        // Delay.
      }, delay);

      /*
        =====
        NOTE:
        =====

          We want to ensure that the modal is currently
          visible, because we do not want to put scroll
          back on the `<html>` element unnecessarily.

          The reason is that another `<product-modal>` in
          the page might have been pre-rendered with an
          [active="true"] attribute. If so, we want to
          leave the page's overflow value alone.
        */
    } else if (isModalVisible) {
      // Set flag.
      if (isMotionOkay) {
        this._isHideShow = true;
        this._modalScroll.setAttribute(DATA_HIDE, TRUE);
      }

      // Fire callback?
      callback();

      // Await CSS animation.
      this._timerForHide = window.setTimeout(() => {
        // Clear.
        clearTimeout(this._timerForHide);

        // Remove flag.
        this._isHideShow = false;
        this._modalScroll.removeAttribute(DATA_HIDE);

        // Hide modal.
        this._modalScroll.style.display = NONE;

        // Show scrollbar.
        document.documentElement.style.overflow = EMPTY_STRING;

        // Remove placeholder.
        document.documentElement.style.paddingRight = EMPTY_STRING;

        // Delay.
      }, delay);
    }
  }
  _handleClickOverlay(event) {
    // Early exit.
    if (this._isHideShow) {
      return;
    }

    // Get layer.
    const target = event.target;

    // Outside modal?
    if (target.classList.contains('product-modal__overlay')) {
      this._handleClickToggle();
    }
  }
  _handleClickToggle(event) {
    // Set later.
    let key = EMPTY_STRING;
    let target = null;

    // Event exists?
    if (event) {
      if (event.target) {
        target = event.target;
      }

      // Get key.
      if (event.key) {
        key = event.key;
        key = key.toLowerCase();
      }
    }

    // Set later.
    let button;

    // Target exists?
    if (target) {
      // Direct click.
      if (target.classList.contains('product-modal__close')) {
        button = target;

        // Delegated click.
      } else if (typeof target.closest === FUNCTION) {
        button = target.closest('.product-modal__close');
      }
    }

    // Get booleans.
    const isValidEvent = event && typeof event.preventDefault === FUNCTION;
    const isValidClick = button && isValidEvent && !key;
    const isValidKey = button && isValidEvent && [ENTER, SPACE].includes(key);

    const isButtonDisabled = button && button.disabled;
    const isButtonMissing = isValidEvent && !button;
    const isWrongKeyEvent = key && !isValidKey;

    // Early exit.
    if (isButtonDisabled || isButtonMissing || isWrongKeyEvent) {
      return;
    }

    // Prevent default?
    if (isValidKey || isValidClick) {
      event.preventDefault();
    }

    // Set flag.
    this._isActive = !this._isActive;

    if (!this._isActive) {
      this._trackModalEvent('closed');
    }
    // Set display.
    this._toggleModalDisplay(() => {
      // Focus modal?
      if (this._isActive) {
        this._focusModal();

        // Return focus?
      } else if (this._activeElement) {
        this._focusElement(this._activeElement);
      } else {
        if (this._gallery.classList.contains('slick-initialized')) {
          $('.product-modal__images').slick('unslick');
          this._gallery.textContent = '';
        }
      }
    });
  }
  _handleFocusIn() {
    // Early exit.
    if (!this._isActive) {
      return;
    }

    // prettier-ignore
    const activeElement = (
        // Get active element.
        this._shadow.activeElement ||
        document.activeElement
      );

    // Get booleans.
    const isFocusTrap1 = activeElement === this._focusTrapList[0];
    const isFocusTrap2 = activeElement === this._focusTrapList[1];

    // Set later.
    let focusListReal;

    // Slot exists?
    if (this._slotForModal) {
      // Get "real" elements.
      focusListReal = Array.from(
        this._slotForModal.querySelectorAll(FOCUSABLE_SELECTORS)
      );
    }

    // Get "shadow" elements.
    const focusListShadow = Array.from(
      this._modal.querySelectorAll(FOCUSABLE_SELECTORS)
    );

    // Get "total" elements.
    const focusListTotal = focusListShadow.concat(focusListReal);

    // Get first & last items.
    const focusItemFirst = focusListTotal[0];
    const focusItemLast = focusListTotal[focusListTotal.length - 1];

    // Focus trap: above?
    if (isFocusTrap1 && focusItemLast) {
      this._focusElement(focusItemLast);

      // Focus trap: below?
    } else if (isFocusTrap2 && focusItemFirst) {
      this._focusElement(focusItemFirst);

      // Outside modal?
    } else if (this._isOutsideModal(activeElement)) {
      this._focusModal();
    }
  }
  _trackModalEvent(event) {
    // GTM event for modal interaction
    dataLayer.push({ event: `quick_add_to_cart_modal_${event}` });
  }
}

customElements.define('product-modal', ProductModal);

export function toggleModal(id, details) {
  // Get modal.
  const modal = document.getElementById(id);
  const product = details.product;
  const collections = details.collections;
  const color = details.color;

  // Modal exists?
  if (modal) {
    // Get flag.
    const bool = modal.getAttribute(ACTIVE) === String(true);

    // Set flag.
    if (color) modal.setAttribute(COLOR, color);
    if (collections)
      modal.setAttribute(COLLECTIONS, JSON.stringify(collections));
    if (product) modal.setAttribute(PRODUCT, JSON.stringify(product));
    modal.setAttribute(ACTIVE, !bool);
  }
}

const textMeBtnModal = document.querySelector('#waitlist-text-me-btn');
const emailMeBtnModal = document.querySelector('#waitlist-email-me-btn');
const emailInputModal = document.querySelector('#waitlist-email');
const telephoneInputModal = document.querySelector('#waitlist-telephone');
const telephoneContainer = document.querySelector(
  '.waitlist-telephone-wrapper'
);
const button = document.querySelector('button[type="submit"]');
const waitListButtonModal = document.querySelector('#waitlist-submit-btn');
const checkboxModal = document.querySelector('#waitlist-marketing');
const checkboxLabelModal = document.querySelector('.waitlist-marketing-label');
const smsComplianceMsgModal = document.querySelector(
  '.waitlist-compliance.sms'
);
let acceptsMarketingModal = checkboxModal.checked;

// swaps active btn class on waitlist text/email btns
function toggleActiveBtn(targetBtn, activeBtn) {
  if (!targetBtn.classList.contains('active')) {
    targetBtn.classList.add('active');
  }
  activeBtn.classList.remove('active');
}

//validators

// hides email input and shows telephone input
function toggleWaitlistTextMeBtn(e) {
  e.preventDefault();
  e.stopPropagation();
  emailInputModal.removeEventListener('input', hideErrorMsg);
  telephoneInputModal.addEventListener('input', hideErrorMsg);
  emailInputModal.style.display = 'none';
  emailInputModal.disabled = true;
  telephoneInputModal.style.display = 'block';
  telephoneContainer.style.display = 'flex';
  telephoneInputModal.disabled = false;
  telephoneInputModal.focus();
  hideErrorMsg();
  if (acceptsMarketingModal) {
    smsComplianceMsgModal.style.display = 'block';
  }
  if (checkboxLabelModal.textContent.includes('email')) {
    checkboxLabelModal.textContent = checkboxLabelModal.textContent.replace(
      'email',
      'text'
    );
  }
  toggleActiveBtn(textMeBtnModal, emailMeBtnModal);
}

// hides telephone input and shows email input
function toggleWaitlistEmailMeBtn(e) {
  e.preventDefault();
  e.stopPropagation();
  telephoneInputModal.removeEventListener('input', hideErrorMsg);
  emailInputModal.addEventListener('input', hideErrorMsg);
  telephoneInputModal.disabled = true;
  telephoneInputModal.style.display = 'none';
  telephoneContainer.style.display = 'none';
  emailInputModal.style.display = 'block';
  emailInputModal.disabled = false;
  emailInputModal.focus();
  hideErrorMsg();
  smsComplianceMsgModal.style.display = 'none';
  if (checkboxLabelModal.textContent.includes('text')) {
    checkboxLabelModal.textContent = checkboxLabelModal.textContent.replace(
      'text',
      'email'
    );
  }
  toggleActiveBtn(emailMeBtnModal, textMeBtnModal);
}

function handleWaitlistSubmit() {
  const marketingContainer = document.querySelector(
    '.marketing-checkbox-container'
  );
  marketingContainer.style.display = 'none';
  waitListButtonModal.disabled = true;
  // handles svg inline button textContent
  let currentHTML = waitListButtonModal.innerHTML;
  let newHTML = currentHTML.replace('JOIN WAITLIST', 'JOINED WAITLIST');
  waitListButtonModal.innerHTML = newHTML;
  waitListButtonModal.title = 'Joined Waitlist';
  // make svg visible
  waitListButtonModal.children[0].style.display = 'block';
}

// error handlers
function waitlistErrorHandler(inputString) {
  const errorMsg = document.querySelector('.waitlist-error');
  errorMsg.style.display = 'block';
  errorMsg.textContent = `Please enter a valid ${inputString}.`;
}

function hideErrorMsg() {
  const errorMsg = document.querySelector('.waitlist-error');
  errorMsg.style.display = 'none';
}

// event listener
checkboxModal.addEventListener('change', () => {
  acceptsMarketingModal = checkboxModal.checked;
  if (acceptsMarketingModal && textMeBtnModal.classList.contains('active')) {
    smsComplianceMsgModal.style.display = 'block';
  } else {
    smsComplianceMsgModal.style.display = 'none';
  }
});
